<template>
  <div class="scrolling-wrapper d-flex py-2 mt-2">
        <div
          v-for="(category, i) in categories"
          :key="i"
          class="mr-3 mb-3 item-scroll float-left rounded elevation-2"
          :class="
            category == categorySelected ? 'grey darken-3 white--text' : 'white'
          "
          @click="setCategory(category)"
        >
          <div class="pt-3 px-4 pb-2 font-weight-medium">
            {{ category.name }}
          </div>
          <div
            class="pt-1 rounded-b"
            :style="{
              backgroundColor: '#' + category.color,
            }"
          ></div>
        </div>
      </div>
</template>

<script>
export default {
  name: "HorizontalCategoriesList",
  props: {
    callBackCategory: {
      type: Function,
      required: true,
    },
    callBackCategories: {
      type: Function,
      required: true,
    },
  },

  data: () => ({
    loading: false,
    tryAgain: false,
    categories: [],
    categorySelected: null,
  }),

  methods: {
    setCategory(category) {
      if (this.categorySelected != category) {
        this.categorySelected = category;
      } else {
        this.categorySelected = null;
      }
    },

    getCategories() {
      this.loading = true;
      this.tryAgain = false;

      this.$axios.get("/web/categories/" + this.$route.params.businessId)
      .then(response => {
        this.categories = response.data;
      })
      .catch(error => {
        console.log(error);
        this.$showMessage("error", "Erro ao carregar as categorias");
        this.tryAgain = true;
      })
      .finally(() => {
        this.loading = false;
      });
    },
  },

  mounted() {
    this.getCategories();
  },
  watch: {
    categories(){
      this.callBackCategories(this.categories);
    },
    categorySelected(){
      this.callBackCategory(this.categorySelected);
    },
  }
};
</script>

<style>
.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.item-scroll {
  display: inline-block;
}
.example::-webkit-scrollbar {
  display: none;
}
</style>
