<template>
  <v-dialog v-model="dialog" fullscreen max-width="400" max-height="600" >
    <v-card tile dark>
      <v-toolbar class="justify-end d-flex" dark>
        <v-btn icon @click="dialog = false">
        <v-icon dark> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
        width="100%"
        :src="image"
      />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    image: null,
  }),

  methods: {
    open(image) {
      this.image = image;
      this.dialog = true;
    },
  },
};
</script>