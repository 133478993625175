<template>
  <div class="fill-height" ref="divContentApp">
    <div class="" v-if="product">
      <!-- v-if="product"-->
      <!-- <v-card class="" width="100%"> -->
      <div class="
          pa-2
          rounded
          d-flex
          align-center
          white--text
          font-weight-bold
          text-uppercase
          justify-center
          ma-2
        " style="min-height: 50px" :style="{
          backgroundColor: '#' + product.category.color,
        }">
        {{ product.category.name }}
      </div>
      <div style="min-height: 100px">
        <v-carousel height="310px" delimiter-icon="mdi-minus" :show-arrows="product.images.length < 2 ? false : true"
          hide-delimiter-background>
          <v-carousel-item v-show="product.images.length > 0" v-for="(image, i) in product.images" :key="i">
            <v-card class="mx-2">
              <v-img @click="openImage($FILES_URL + image.url)" min-height="300px" max-height="300px" width="100%"
                contain class="mb-4" :src="$FILES_URL + image.url" />
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </div>
      <div class="d-flex mb-2 align-center" style="position: relative; overflow: auto">
        <div class="flex-grow-1 px-2 font-weight-medium" :class="{
          'text-h6': product.name.length < 50,
          'text-subtitle-1': product.name.length >= 50,
        }" style="line-height: 110%; word-wrap: break-word; overflow: auto">
          {{ product.name }}
        </div>
        <div v-if="product.price != 0.00" class="d-flex justify-end px-2">
          <div label class="grey lighten-2 rounded d-flex">
            <div label class="
               blue-grey darken-2
                white--text
                text-h5
                font-weight-black
                pa-4
                text-center
                rounded
                d-flex
                align-center
              ">
              R$
            </div>
            <div class="
                px-2
                d-flex
                flex-column
                align-right
                justify-center
                text-h5
                black--text
              ">
              <span :class="{
                'error--text text--darken-1': product.promotion_price,
                'text-subtitle-1 ': product.promotion_price,
              }" :style="
  product.promotion_price
    ? 'textDecoration: line-through'
    : 'font-weight: bold'
">
                {{ $utils.formatMoney(product.price) }}
              </span>
              <template v-if="product.promotion_price">
                <div class="font-weight-bold mt-n2">
                  {{ $utils.formatMoney(product.promotion_price) }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- </v-card> -->
      <div class="d-flex justify-end mb-4 mx-2" style="height: 50px">
        <MainActionButton style="height: 50px" class="py-2" :product_name="product.name" />
        <v-btn @click="shareProduct()" large class="py-2 ml-2" style="height: 50px">
          <v-icon> mdi-share-variant </v-icon>
        </v-btn>
      </div>
      <v-card class="pa-4 mx-2 d-flex flex-column justify-center">
        <v-expand-transition appear>
          <div
            v-html="showDescription(product.description)"
          />
        </v-expand-transition>
        <v-btn class="mt-2" text small v-if="product.description.length > 80" @click="readMore = !readMore">
          {{ readMore ? "Ver Menos" : "Ver Mais" }}
          <v-icon>{{ !readMore ? "mdi-chevron-down" : "mdi-chevron-up" }}</v-icon>
        </v-btn>
      </v-card>
      <HorizontalCategoriesList :callBackCategory="setCategory" :callBackCategories="setCategories" class="ml-2" />
      <span v-if="products.length <= 0" class="d-flex justify-center text-center">Ops! Ainda estamos adicionando.
        Aguarde.</span>
      <ProductList :callBackProduct="setProducts" :categorySelected="categorySelected" :scrollingLeft="false"
        :actualProduct="product" class="mx-2" />
    </div>
    <div v-else class="d-flex fill-height align-center justify-center text-center">
      <v-progress-circular indeterminate v-if="loadingProduct" color="secondary" />
      <div v-else-if="tryAgain">
        <h3>Não foi possível encontrar o {{ serviceORproduct }}</h3>
        <v-btn class="mb-4 secondary" dark @click="getBusinessInfo()">
          Tente Novamente
        </v-btn>
      </div>
      <h3 v-else>Este {{ $offerType() }} não existe</h3>
    </div>
    <template>
      <ImageViewDialog ref="imageDialog" />
    </template>
  </div>
</template>

<script>
import ProductList from "@/components/ProductList.vue";
import MainActionButton from "@/components/MainActionButton.vue";
import ImageViewDialog from "../components/ImageViewDialog.vue";
import HorizontalCategoriesList from "../components/HorizontalCategoriesList.vue";

export default {
  name: "Products",
  components: {
    ProductList,
    MainActionButton,
    ImageViewDialog,
    HorizontalCategoriesList,
  },
  data: () => ({
    loadingProduct: false,
    loadingCategories: false,
    tryAgain: false,
    readMore: false,
    refresh: false,

    name: "Temp",

    misteryLoading: false,

    dialog: false,
    product: null,
    favorite_button: null,

    categorySelected: null,

    Pcategories: [],
    products: [],
  }),
  methods: {
    setProducts(products) {
      this.products = products;
    },

    setCategory(category) {
      this.categorySelected = category;
    },
    setCategories(categories) {
      this.Pcategories = categories;
    },

    shareProduct() {
      navigator.share({
        url: this.$SITE_URL + this.$route.path,
      });
    },

    showDescription(description) {
      if (description.length > 80 && !this.readMore) {
        return description.substring(0, 80) + "...";
      } else {
        this.readMore = true;
        return this.$utils.parseTextToHtml(description);
      }
    },
    getProduct() {
      this.loadingProduct = true;
      this.tryAgain = false;

      this.$axios
        .get(
          "/web/products/" +
          this.$route.params.businessId +
          "/" +
          this.$route.params.productId
        )
        .then((response) => {
          this.product = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$showMessage("error", this.$offerType() + " inexistente");
          } else {
            this.$showMessage(
              "error",
              "Erro ao carregar os dados do " + this.$offerType()
            );
            this.tryAgain = true;
          }
        })
        .finally(() => {
          this.loadingProduct = false;
        });
    },

    moveUp() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$nextTick(() => {
        this.$refs.divContentApp?.scrollTo({ top: 0, behavior: "smooth" });
      });
    },
    openImage(image) {
      this.$refs.imageDialog.open(image);
    },
  },

  mounted() {
    this.getProduct();
  },

  watch: {
    "$route.params.productId"() {
      this.refresh = true;
      this.getProduct();
      this.moveUp();
      this.readMore = false;
    },
    product() {
      this.$setNavigation(this.product.name);
    },
    categorySelected() {
      this.moveUp();
    },
  },
};
</script>


<style>
.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.item-scroll {
  display: inline-block;
}

.example::-webkit-scrollbar {
  display: none;
}
</style>
